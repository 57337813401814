import { useUserStore } from '@/config/store/userStore'
import MdiDatabase from '~icons/mdi/database'
import IcBaselineTableChart from '~icons/ic/baseline-table-chart'
import IconDashboard from '~icons/ps/dashboard'
import IconDocumentSearch from '~icons/heroicons-outline/document-search'
import IconSchool from '~icons/ic/twotone-school'
import IconChart from '~icons/ic/sharp-show-chart'
import IconNewDashboard from '~icons/ic/sharp-dashboard-customize'
import PhUsersThree from '~icons/ph/users-three'
import IcOutlinePlaylistAdd from '~icons/ic/outline-playlist-add'
import IcBaselineList from '~icons/ic/baseline-list'
import MdiFormatListText from '~icons/mdi/format-list-text'
import MdiCashCheck from '~icons/mdi/cash-check'
import MdiCashClock from '~icons/mdi/cash-clock'
import MdiCashFast from '~icons/mdi/cash-fast'
import MaterialSymbolsGooglerTravel from '~icons/material-symbols/googler-travel'
import MaterialSymbolsAssignmentAddOutlineSharp from '~icons/material-symbols/assignment-add-outline-sharp'
import MaterialSymbolsAssignmentOutlineRounded from '~icons/material-symbols/assignment-outline-rounded'
import IcBaselinePersonSearch from '~icons/ic/baseline-person-search'
import MaterialSymbolsEventNoteOutlineRounded from '~icons/material-symbols/event-note-outline-rounded'
import PhChartLineUpLight from '~icons/ph/chart-line-up-light'
import MaterialSymbolsNestClockFarsightAnalogOutlineRounded from '~icons/material-symbols/nest-clock-farsight-analog-outline-rounded'
import { guardHrAccess } from '@/config/router/navigationsGuards/guardHr'
import { guardProfiles, guardUsers } from '@/config/router/navigationsGuards/guardSuperAdmin'
import { guardCashFlow, guardDashboardAccess, guardProduction, guardResource, guardSales } from '@/config/router/navigationsGuards/guardDashboard'
import { guardPlanningResource, guardProjectAccess, guardProjectCreation } from '@/config/router/navigationsGuards/guardProject'
import { guardTimesheetAccess } from '@/config/router/navigationsGuards/guardTimesheet'
import { guardCalendar, guardLeaveAccess, guardNewBulkRequest, guardNewRequest, guardRequest } from '@/config/router/navigationsGuards/guardLeave'
import { guardCreationEvaluation, guardEvaluationAccess } from '@/config/router/navigationsGuards/guardEvaluation'
import { guardCandidateBase, guardJobBoard, guardJobBoardAdmin, guardPipe, guardRecruitmentAccess, guardScreening } from '@/config/router/navigationsGuards/guardRecruitment'
import MaterialSymbolsEditDocumentOutlineRounded from '~icons/material-symbols/edit-document-outline-rounded'
import SolarCaseOutline from '~icons/solar/case-outline'
import SolarPaintRollerLineDuotone from '~icons/solar/paint-roller-line-duotone'

export enum bgColors {
  primary = 'text-white bg-primary-500',
  'primary-op' = 'bg-primary-400/20 text-primary-700 dark:bg-primary-500/20 dark:text-primary-500',
  success = 'text-white bg-success-500',
  'success-op' = 'bg-success-400/20 text-success-700 dark:bg-success-500/20 dark:text-success-500',
  warning = 'text-white bg-warning-400',
  'warning-op' = 'bg-warning-400/20 text-warning-600 dark:bg-warning-500/20 dark:text-warning-500',
  base = 'bg-base border border-base',
  'base-varient' = 'bg-fade',
  error = 'text-white bg-red-700',
  'error-op' = 'bg-danger-400/20 text-danger-500 dark:bg-danger-500/20 dark:text-danger-400',
  errorLight = 'text-white bg-red-400',
  'errorLight-op' = 'bg-danger-300/20 text-danger-300 dark:bg-danger-300/20 dark:text-danger-300',
  loading = 'opacity-70',
  disabled = 'text-white text-shadow bg-gray-400 dark:bg-gray-500',
  'disabled-op' = 'text-gray-800/70 dark:text-gray-300 bg-gray-600/20 dark:bg-gray-500/80',
  disabledLight = 'bg-fade',
  transparent = 'bg-transparent',
}

export const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

export enum labelColors {
  primary = 'bg-primary-400/20 text-primary-700 dark:bg-primary-500/20 dark:text-primary-500',
  'primary-plain' = 'bg-primary-400 text-gray-100 dark:bg-primary-500 dark:text-primary-100',
  success = 'bg-success-400/20 text-success-700 dark:bg-success-500/20 dark:text-success-500',
  'success-plain' = 'bg-success-400 text-success-800 dark:bg-success-500 dark:text-success-100',
  warning = 'bg-warning-400/20 text-warning-600 dark:bg-warning-500/20 dark:text-warning-500',
  'warning-plain' = 'bg-warning-400 text-warning-800 dark:bg-warning-500 dark:text-warning-100',
  base = 'bg-base border-base border',
  'base-varient' = 'bg-fade',
  error = 'bg-danger-400/20 text-danger-500 dark:bg-danger-500/20 dark:text-danger-400',
  'error-plain' = 'bg-danger-600 text-danger-100 dark:bg-danger-700 dark:text-danger-100',
  errorLight = 'bg-danger-300/20 text-danger-300 dark:bg-danger-300/20 dark:text-danger-300',
  loading = 'opacity-70',
  disabled = 'text-gray-50 text-shadow bg-gray-500 base-border',
  disabledLight = 'bg-fade',
  transparent = 'bg-transparent',
}
// error = 'bg-danger-500/80 text-danger-50 dark:bg-danger-500/20 dark:text-danger-500',

export enum size {
  mini = 'text-xs p-1',
  dense = 'text-xs px-2 py-1',
  small = 'text-sm px-2 py-1',
  normal = 'text-base px-2 py-1',
  large = 'text-lg px-2 py-1',
}

export enum ModalPosition {
  base = 'b-modal',
  bottom = 'bottom-modal',
  right = 'right-modal',
  left = 'left-modal',
}

// is in production
export const isProduction = import.meta.env.PROD
const isSandbox = location.hostname.split('.').includes('app-sandbox')

const shouldBeDisplayed = (acc, item) => {
  item.subPages = item.subPages.reduce((acc, item) => {
    if ((!isProduction || !item.isAlpha || (item.isAlpha && isSandbox)) && item.isDisplayed)
      acc.push(item)
    return acc
  }, [])

  if ((!isProduction || !item.isAlpha || (item.isAlpha && isSandbox)) && item.isDisplayed)
    acc.push(item)
  return acc
}

export const getDrawerMenuSuperAdmin = () => [
  {
    moduleId: 'SUPER_ADMIN',
    display: 'common.drawerSuperAdmin.users.title',
    isHeader: true,
    prependIcon: PhUsersThree,
    appendBadge: false,
    color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
    moduleName: 'SUPER_ADMIN',
    isDisplayed: guardUsers() === true,
    subPages: [
      {
        moduleId: 'SUPER_ADMIN',
        isDisplayed: guardUsers() === true,
        display: 'common.drawerSuperAdmin.users.listOfUsers',
        route: 'SuperAdminUsers',
        isHeader: false,
        prependIcon: PhUsersThree,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 'SUPER_ADMIN',
        isDisplayed: guardProfiles() === true,
        display: 'common.drawerSuperAdmin.users.profileList',
        route: 'SuperAdminProfiles',
        isHeader: false,
        prependIcon: PhUsersThree,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
    ],
  },
  {
    moduleId: 'SUPER_ADMIN',
    display: 'common.drawerSuperAdmin.firm.title',
    isHeader: true,
    prependIcon: SolarCaseOutline,
    appendBadge: false,
    color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
    moduleName: 'SUPER_ADMIN',
    isDisplayed: guardUsers() === true,
    subPages: [
      {
        moduleId: 'SUPER_ADMIN',
        isDisplayed: guardUsers() === true,
        display: 'common.drawerSuperAdmin.firm.personalisation',
        route: 'SuperAdminPersonalisation',
        isHeader: false,
        prependIcon: SolarPaintRollerLineDuotone,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
    ],
  },
].reduce(shouldBeDisplayed, [])

export const getDrawerMenu = () => [
  {
    moduleId: 1,
    display: 'common.drawer.hrBase',
    isHeader: true,
    prependIcon: PhUsersThree,
    appendBadge: false,
    color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
    moduleName: 'HR',
    isDisplayed: guardHrAccess() === true,
    subPages: [
      {
        moduleId: 1,
        isDisplayed: true,
        display: 'common.drawer.directory',
        route: 'HrBase',
        isHeader: false,
        prependIcon: PhUsersThree,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 1,
        isDisplayed: true,
        display: 'common.drawer.hrStats',
        route: 'HrStats',
        isHeader: false,
        prependIcon: PhChartLineUpLight,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
    ],
  },
  {
    moduleId: 4,
    display: 'common.drawer.recruitment',
    isHeader: true,
    prependIcon: IconDocumentSearch,
    appendBadge: false,
    color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
    isBeta: true,
    moduleName: 'RECRUITMENT',
    isDisplayed: guardRecruitmentAccess() === true,
    subPages: [
      {
        moduleId: 4,
        isDisplayed: guardScreening() === true,
        display: 'common.drawer.screening',
        route: 'Screening',
        isHeader: false,
        isBeta: true,
        prependIcon: IconDocumentSearch,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 4,
        isDisplayed: guardPipe() === true,
        display: 'common.drawer.recruitmentProcess',
        route: 'RecruitmentProcess',
        isHeader: false,
        isBeta: true,
        isAlpha: false,
        prependIcon: IcBaselineTableChart,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        display: 'common.drawer.recruitmentDatabase',
        isDisplayed: guardCandidateBase() === true,
        route: 'RecruitmentDatabase',
        isHeader: false,
        isBeta: true,
        isAlpha: false,
        prependIcon: MdiDatabase,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        display: 'common.drawer.jobBoard',
        isDisplayed: guardJobBoard() === true,
        route: 'JobBoardAdmin',
        isHeader: false,
        isAlpha: false,
        isBeta: true,
        prependIcon: IconChart,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 4,
        isDisplayed: true,
        display: 'common.drawer.hrStats',
        route: 'RecruitmentStats',
        isHeader: false,
        prependIcon: PhChartLineUpLight,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
    ],
  },
  {
    moduleId: 5,
    isDisplayed: guardProjectAccess() === true,
    display: 'common.drawer.project',
    isHeader: true,
    prependIcon: IconChart,
    appendBadge: false,
    color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
    moduleName: 'PROJECT',
    subPages: [
      {
        moduleId: 5,
        isDisplayed: guardProjectCreation() === true,
        display: 'common.drawer.createProject',
        route: 'ProjectCreation',
        isHeader: false,
        prependIcon: IcOutlinePlaylistAdd,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 5,
        isDisplayed: true,
        display: 'common.drawer.projectList',
        route: 'Projects',
        isHeader: false,
        prependIcon: MdiFormatListText,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 5,
        isDisplayed: true,
        display: 'common.drawer.projectAdvancement',
        route: 'ProjectsAdvancement',
        isHeader: false,
        prependIcon: MdiFormatListText,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 5,
        isDisplayed: guardPlanningResource() === true,
        display: 'common.drawer.projectResourcePlanning',
        route: 'ProjectPlanningResources',
        isHeader: false,
        prependIcon: IcBaselineList,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
    ],
  },
  {
    moduleId: 20,
    isDisplayed: guardTimesheetAccess() === true,
    display: 'common.drawer.timesheet',
    isHeader: true,
    prependIcon: MaterialSymbolsNestClockFarsightAnalogOutlineRounded,
    appendBadge: false,
    color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
    moduleName: 'TIMESHEET',
    subPages: [
      {
        moduleId: 20,
        isDisplayed: guardTimesheetAccess() === true,
        display: 'common.drawer.timesheets',
        route: 'Timesheets',
        isHeader: false,
        prependIcon: MaterialSymbolsNestClockFarsightAnalogOutlineRounded,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
    ],
  },
  {
    moduleId: 6,
    isDisplayed: guardLeaveAccess() === true,
    display: 'common.drawer.leave',
    isHeader: true,
    prependIcon: MaterialSymbolsGooglerTravel,
    appendBadge: false,
    color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
    moduleName: 'LEAVE',
    subPages: [
      {
        moduleId: 6,
        isDisplayed: guardLeaveAccess() === true,
        display: 'common.drawer.leaveAccount',
        route: 'LeaveAccount',
        isHeader: false,
        prependIcon: MaterialSymbolsGooglerTravel,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 6,
        isDisplayed: guardRequest() === true,
        display: 'common.drawer.leaveRequest',
        route: 'LeaveRequests',
        isHeader: false,
        prependIcon: MaterialSymbolsAssignmentOutlineRounded,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 6,
        isDisplayed: guardNewRequest() === true,
        display: 'common.drawer.leaveNewRequest',
        route: 'NewLeaveRequest',
        isHeader: false,
        prependIcon: MaterialSymbolsAssignmentAddOutlineSharp,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 6,
        isDisplayed: guardNewBulkRequest() === true,
        display: 'common.drawer.leaveNewRequestBulk',
        route: 'NewBulkLeaveRequest',
        isHeader: false,
        prependIcon: MaterialSymbolsAssignmentAddOutlineSharp,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 6,
        isDisplayed: guardCalendar() === true,
        display: 'common.drawer.LeaveCalendar',
        route: 'LeaveCalendar',
        isHeader: false,
        prependIcon: MaterialSymbolsEventNoteOutlineRounded,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
    ],
  },
  {
    moduleId: 7,
    isDisplayed: guardEvaluationAccess() === true,
    display: 'common.drawer.evaluation',
    isHeader: true,
    prependIcon: MaterialSymbolsEditDocumentOutlineRounded,
    appendBadge: false,
    color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
    moduleName: 'EVALUATION',
    subPages: [
      {
        moduleId: 7,
        isDisplayed: guardEvaluationAccess() === true,
        display: 'common.drawer.evaluationList',
        route: 'Evaluations',
        isHeader: false,
        prependIcon: MdiFormatListText,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 7,
        isDisplayed: guardCreationEvaluation() === true,
        display: 'common.drawer.evaluationCreate',
        route: 'EvaluationCreation',
        isHeader: false,
        prependIcon: MdiFormatListText,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
    ],
  },

  {
    moduleId: 9,
    display: 'common.drawer.formation',
    isHeader: true,
    prependIcon: IconNewDashboard,
    appendBadge: false,
    color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
    moduleName: 'TRAINING',
    isAlpha: true,
    subPages: [
      {
        moduleId: 9,
        display: 'common.drawer.formationNew',
        route: 'TrainingNew',
        isHeader: false,
        prependIcon: IconNewDashboard,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 9,
        display: 'common.drawer.formationList',
        route: 'TrainingList',
        isHeader: false,
        prependIcon: IconSchool,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 9,
        display: 'common.drawer.formationCalendar',
        route: 'TrainingPlanning',
        isHeader: false,
        prependIcon: IconSchool,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
    ],
  },
  {
    moduleId: 23,
    display: 'Dashboard',
    isHeader: true,
    prependIcon: IconDashboard,
    appendBadge: false,
    color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
    moduleName: 'DASHBOARD',
    isDisplayed: guardDashboardAccess() === true,
    subPages: [
      {
        moduleId: 23,
        isDisplayed: guardProduction() === true,
        display: 'Production',
        route: 'DashboardProduction',
        isHeader: false,
        prependIcon: MdiCashCheck,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 23,
        isDisplayed: guardSales() === true,
        display: 'Sales',
        route: 'DashboardSales',
        isHeader: false,
        prependIcon: MdiCashClock,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 23,
        isDisplayed: guardCashFlow() === true,
        display: 'Cashflow',
        route: 'DashboardCashflow',
        isHeader: false,
        prependIcon: MdiCashFast,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
      {
        moduleId: 23,
        isDisplayed: guardResource() === true,
        display: 'Resource',
        route: 'DashboardResource',
        isHeader: false,
        prependIcon: IcBaselinePersonSearch,
        appendBadge: false,
        color: 'hover:border-primary-500 hover:bg-primary-500 hover:bg-opacity-25',
      },
    ],
  },

].reduce(shouldBeDisplayed, [])
